<template>
    <v-main style="min-height: 80% !important">
        <v-container class="pb-12">
            <v-row>
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class="text-left mb-3"
                        :class="$mq != 'lg' ? 'mt-3' : 'mt-6'"
                        v-show="!managmentProduct.loading"
                    >
                        Editar produto
                    </h2>
                </v-col>
            </v-row>
            <v-skeleton-loader
                v-if="managmentProduct.loading"
                v-bind="attrs"
                height="100%"
                width="100%"
                type="list-item-avatar-three-line, image, article, actions"
                elevation="0"
            ></v-skeleton-loader>
            <template v-else>
                <v-row>
                    <v-card
                        width="100%"
                        elevation="0"
                    >
                        <v-card elevation="2" color="transparent" width="100%" class="mb-7">
                            <v-subheader class="font-weight-bold text-h5">Dados</v-subheader>
                            <v-list dense>
                                <v-list-item style="min-height: 25px !important">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-left grey--text"><span class="font-weight-bold">Referência: </span>{{ product.pr0produto }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item style="min-height: 25px !important">
                                    <v-list-item-content>
                                        <v-list-item-title class="text-left grey--text"><span class="font-weight-bold"><b>Descrição: </b></span>{{ product.pr0desc | capitalize }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card elevation="2" color="transparent" width="100%" class="mb-7">
                            <v-subheader class="font-weight-bold text-h5">Imagens do produto</v-subheader>
                            <v-file-input
                                v-model="images"
                                accept="image/png, image/jpeg, image/bmp"
                                placeholder="Adicione fotos ao produto"
                                prepend-icon="mdi-camera"
                                label="imagem"
                                show-size
                                counter
                                multiple
                                small-chips
                                :color="parameters.secondaryColor"
                                :error="imagesError"
                                :error-messages="messagesError"
                                class="px-5"
                            >
                                <template v-slot:selection="{ index, text }">
                                    <v-chip
                                        v-if="index < 2"
                                        :color="parameters.secondaryColor"
                                        small
                                        class="ma-2"
                                        close
                                        @click:close="removeImage(index)"
                                        text-color="white"
                                    >
                                        {{ text }}
                                    </v-chip>

                                    <span
                                        v-else-if="index === 2"
                                        class="text-overline grey--text text--darken-3 mx-2"
                                    >
                                        +{{ images.length - 2 }} foto(s)
                                    </span>
                                </template>
                            </v-file-input>
                            <!-- <v-list dense>
                                <v-subheader inset v-if="lstB2i.length > 0">Imagens adicionadas</v-subheader>

                                <v-list-item v-for="(b2i, i) in lstB2i" :key="i">
                                    <v-list-item-avatar class="my-0">
                                        <v-icon small :color="parameters.primaryColor" dark>
                                            mdi-image
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="text-left">{{ `${i+1}. ${b2i.name}` }}</v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-action class="my-0">
                                        <v-btn icon small>
                                            <v-icon small color="red" @click="removeB2i(i)">mdi-close</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list> -->

                            <v-subheader>Imagens cadastradas</v-subheader>

                            <v-slide-group class="px-4" center-active show-arrows>
                                <v-slide-item

                                    v-for="(b2i, i) in product.lstB2i"
                                    :key="i"
                                >
                                    <v-card class="ma-4" height="190" width="210" :color="parameters.primaryColor">
                                        <v-img :src="b2i.b2icaminho" height="100%" dark :aspect-ratio="4/3">
                                            <v-btn small icon class="" @click="setMain(b2i)" absolute style="top: 2px !important; left: 2px !important;">
                                                <v-icon small color="yellow">{{ b2i.b2iprincip ? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
                                            </v-btn>

                                            <v-btn small color="red" icon @click="deleteImage(b2i.b2icodigo)" absolute style="top: 2px !important; right: 2px !important;">
                                                <v-icon small>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-img>
                                    </v-card>
                                </v-slide-item>
                            </v-slide-group>
                            <p class="grey--text pb-3" v-if="product.lstB2i == null || product.lstB2i.length == 0">Nenhuma imagem cadastrada</p>

                        </v-card>

                        <v-alert
                            color="amber lighten-4"
                            style="color: #995c00 !important"
                            dense
                            class="mx-10 caption"
                        >
                            Recomendamos imagens com formato .jpg e dimensões de 1280x900px.
                        </v-alert>

                        <v-card elevation="2" color="transparent" width="100%" class="mb-7">
                            <v-subheader class="font-weight-bold text-h5">Opções de cores</v-subheader>

                            <v-list v-if="product.lstPr2 && product.lstPr2.length > 0">
                                <v-list-item
                                    v-for="pr2 in product.lstPr2"
                                    :key="pr2.pr2opcao"
                                >
                                    <v-list-item-icon>
                                        <v-img 
                                            v-if="pr2.cr1imagem"
                                            :src="pr2.cr1imagem"
                                            aspect-ratio="1"
                                            contain
                                            v-on:error="onImgError(pr2)"
                                        />
                                        <v-card
                                            v-else
                                            dark
                                            :color="pr2.hexadecimal"
                                            height="30"
                                            width="30"
                                            elevation="0"
                                        >
                                        </v-card>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title class="text-left grey--text" v-text="pr2.pr2opcao + ' - ' + pr2.cr1nome"></v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-avatar v-if="pr2.pr2imagem" horizontal size="60" tile>
                                        <v-img height="100%" :src="pr2.pr2imagem" dark :aspect-ratio="4/3">
                                        </v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-icon class="align-self-center">
                                        <v-icon v-if="pr2.pr2imagem" color="primary" @click="deleteImageOption(pr2.pr2opcao)">
                                            mdi-image-remove
                                        </v-icon>
                                        <v-icon v-else color="primary" @click="selectImage(pr2.pr2opcao)">
                                            mdi-image-plus
                                        </v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                            <p class="grey--text" v-else>Nenhuma opção de cor cadastrada para o produto</p>

                            <input 
                                type="file"
                                id="imageColor"
                                name="imageColor"
                                accept="image/png, image/jpeg, image/bmp"
                                style="display: none !important"
                                @input="imageOptionInput">
                        </v-card>

                        <v-card elevation="2" color="transparent" width="100%" class="mb-7">
                            <v-subheader class="font-weight-bold text-h5 pt-4">Ficha técnica</v-subheader>

                            <div class="el-tiptap-editor__wrapper px-3">
                                <el-tiptap
                                    v-model="product.pr0ficha"
                                    :extensions="extensions"
                                    :menu-bubble-options="{
                                        'keep-in-bounds': false,
                                    }"
                                    placeholder="Descrava aqui a ficha técnica do produto"
                                />
                            </div>

                        </v-card>

                        <v-card-text class="">
                            <v-btn
                                elevation="0"
                                :color="parameters.primaryColor"
                                text
                                @click="$router.push('/manterprodutos')"
                                >Cancelar</v-btn
                            >
                            <v-btn
                                elevation="0"
                                dark
                                :color="parameters.secondaryColor"
                                @click="save"
                                >Salvar</v-btn
                            >
                        </v-card-text>
                    </v-card>
                </v-row>
            </template>
        </v-container>
    </v-main>
</template>

<script>
import {
    // necessary extensions
    Doc,
    Text,
    Paragraph,
    Heading,
    Bold,
    Underline,
    Italic,
    Strike,
    Code,
    FontType,
    FontSize,
    TextColor,
    TextHighlight,
    FormatClear,
    Link,
    Image,
    Blockquote,
    ListItem,
    BulletList,
    OrderedList,
    TodoItem,
    TodoList,
    TextAlign,
    Indent,
    HardBreak,
    HorizontalRule,
    CodeView,
    Fullscreen,
    History,
} from "element-tiptap";

import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags

export default {
    data: () => ({
        attrs: {
            class: "mb-6",
            tile: true,
            elevation: 2,
        },
        extensions: [
            new Doc(),
            new Text(),
            new Paragraph(),
            new Heading({ level: 5 }),
            new Bold({ bubble: true }),
            new Underline({ bubble: true }),
            new Italic({ bubble: true }),
            new Strike({ bubble: true }),
            new Code(),
            /* new Link({ bubble: true }),
            new Image(), */
            new Blockquote(),
            new FontType({ bubble: true }),
            new FontSize({ bubble: true }),
            new TextColor({ bubble: true }),
            new TextHighlight({ bubble: true }),
            new FormatClear({ bubble: true }),
            new TextAlign(),
            new ListItem(),
            new BulletList({ bubble: true }),
            new OrderedList({ bubble: true }),
            new TodoItem(),
            new TodoList(),
            new Indent(),
            new HardBreak(),
            new HorizontalRule({ bubble: true }),
            new CodeView({
                codemirror,
                codemirrorOptions: {
                    styleActiveLine: true,
                    autoCloseTags: true,
                },
            }),
            new Fullscreen(),
            new History(),
        ],
        images: [],
        lstB2i: [],
        imagesError: false,
        messagesError: [],
        selectedPr2opcao: ""
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        managmentProduct: {
            get() {
                return this.$store.state.managmentProduct;
            },
        },
        product: {
            get() {
                return this.$store.state.managmentProduct.product;
            },
        },
    },
    methods: {
        save() {
            for (const image of this.images) {
                if (image.size > 41943040) {
                    this.imagesError = true;
                    this.messagesError = [
                        "Imagens só podem ter no máximo 40MB!",
                    ];
                    return false;
                }
            }
            this.$store.dispatch("managmentProduct/update", this.images)
            .then(() => {
                this.images = []
                this.imagesError = false
                this.messagesError = []
            });
        },
        removeImage(index) {
            this.images.splice(index, 1);
        },
        removeB2i(index) {
            this.lstB2i.splice(index, 1);
        },
        setMain(b2i) {
            b2i.b2iprincip = !b2i.b2iprincip;
            this.$store.dispatch("managmentProduct/setMain", b2i);
        },
        deleteImage(b2icodigo) {
            this.$store.dispatch("managmentProduct/deleteImage", b2icodigo);
        },
        onImgError(prop){
            prop.cr1imagem = ""
        },
        selectImage(pr2opcao){
            let input = document.getElementById("imageColor")
            input.click()
            this.selectedPr2opcao = pr2opcao
        },
        imageOptionInput(event){
            let input = document.getElementById("imageColor")
            const files = input.files
            if(files[0]){
                const file = files[0]
                if (file.size > 41943040) {
                    this.selectedPr2opcao = ""
                    this.$store.dispatch("parameters/showSnackBar", {
                        text: "Imagens só podem ter no máximo 40MB!",
                        show: true,
                        timeout: 6000
                    })
                    return false;
                }

                this.$store.dispatch("managmentProduct/setOptionImage", {image: file, pr2opcao: this.selectedPr2opcao})
                .then(this.selectedPr2opcao = "")
            }
        },
        deleteImageOption(pr2ocao){
            this.$store.dispatch("managmentProduct/deleteImageOption", pr2ocao)
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.$store.dispatch(
            "managmentProduct/find",
            this.$route.params.pr0produto
        );
        next();
    },
    mounted() {
        this.$store.dispatch(
            "managmentProduct/find",
            this.$route.params.pr0produto
        );
    },
};
</script>

<style>
</style>